section {
  background-color: #151515;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border: 1px solid #000;
  background-color: black;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: white;
  /* -webkit-box-shadow: inset 0 0 6px white; */
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: white;
  /* -webkit-box-shadow: inset 0 0 6px white; */
}

::-webkit-scrollbar-thumb:window-inactive {
  background: white;
}

.search {
  display: flex;
  padding: 50px 10px 25px 10px;
  background-color: #151515;
  max-width: 1525px;
  margin: 0 auto;
}

.settings {
  border: 2px solid #21db70;
  border-radius: 100px;
  padding: 10px;
  color: #21db70;
  cursor: pointer;
  margin-right: 15px;
  position: absolute;
}

.search-wrap {
  /* border-bottom: 2px solid #515151; */
  padding-bottom: 15px;
  margin-right: 50px;
  display: flex;
  position: relative;
  width: 317px;
}

.search-icon {
  display: flex;
  cursor: pointer;
  width: 40px;
  height: 40px;
  /* background-color: #21db70; */
  justify-content: center;
  border-radius: 100%;
  border: 2px solid #21db70;
  align-items: center;
  background-image: url("../../../../img/icon-filter.png");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
}

.search-icon-clicked {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 108px;
  border: 2px solid #21db70;
  background-color: #21db70;
  align-items: center;
  /* background-image: url("../../../../img/icon-filter.png"); */
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 25px;
  position: absolute;
}

.search-icon-clicked:hover {
  color: #FFFFFF;
}

.sliders {
  border: 2px solid black;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-wrap i,
.settings-show i {
  color: #555555;
}

.search #label,
.settings-show label {
  color: white;
}

.search input[type="text"],
.settings-show input {
  color: #555555;
  background-color: #151515;
  border: none;
  outline: none;
}

.search input[type="text"]:focus {
  color: #ffffff;
}

.search input[type="text"]:not([value=""]) {
  color: #ffffff;
}

.search.show {
  display: none;
}

.wrap-filters {

}

.wrap-filters li {
  color: #989898;
  padding-top: 10px;
  margin-bottom: 10px;
  margin-left: 62px;
  position: relative;
}

#label {
  font-family: "suisse_intl_monoregular";
  font-size: 11px;
  line-height: 38px;
  white-space: nowrap;
}

.search input {
  font-family: "suisse_intl_monoregular";
  font-size: 11px;
  line-height: 38px;
}

.searchIcon {
  color: #555555;
  align-self: center;
}

/* .iconFilter {
  position: absolute;
} */

.shape {
  position: absolute;
  top: 10px;
  /* display: none; */
}

.line {
  border-bottom: 2px solid #515151;
  width: 100%;
}

.fa-search {
  color: #555555;
  align-self: center;
  position: absolute;
  right: 0;
  cursor: pointer;
}

button {
  background-color: transparent;
  border: transparent;
  outline: transparent;
}

.hidden,
.switch-off {
  display: none;
}

.show,
.switch-on {
  display: block;
}

/* .search-icon {
  background-image: url("../../../../img/shape.png");
} */

.search-icon:hover {
  background-color: #21db70;
  background-image: url("../../../../img/shape.png");
  background-repeat: no-repeat;
  background-position: center;
}

.clicked-x {
  position: absolute;
  bottom: 8px;
}
.search-icon-clicked::after {
  content: "";
  display: block;
  border-top: 2px solid #555555;
  position: absolute;
  top: 77px;
  width: 20px;
  opacity: 0.2;
}

.search-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #303030;
  max-height: 450px;
  width: 100%;
  padding: 40px 10px;
}

.search-form-wrapper > div:not(:last-child) {
  margin-bottom: 40px;
}

.form-checkboxes-wrapper {
  display: flex;
  justify-content: center;
  max-height: 450px;
  width: 100%;
  font-family: "suisse_intl_monoregular";
  font-size: 14px;
  font-weight: 400;
}

.form-checkboxes-wrapper > div:not(:last-child) {
  margin-right: 70px;
}


.form-checkboxes-item {
  width: 300px;
}

.form-checkboxes-item > div:not(:last-child) {
  margin-bottom: 15px;
}

.filter-title {
  padding-bottom: 15px;
  border-bottom: 1px solid #707070;
  color: white;
  font-size: 14px;
}

.filter-content {
  max-height: 200px;
  overflow: auto;
}

.filter-search-button {
  font-family: "suisse_intl_monoregular";
  width: 188px;
  height: 48px;
  background-color: #21db70;
  color: #151515;
  font-size: 18px;
  line-height: 38px;
  border-radius: 25px;
  cursor: pointer;
}

.filter-search-button:hover {
  background-color: #008332;
  color: #FFFFFF;
  transition: all .3s ease-in-out;
}

.search-input-wrapper {
  display: flex;
  margin-left: 71px;
}

.search-input-wrapper > div:not(:last-child){
  margin-right: 15px;
}

.search-input-wrapper:hover .loupe-icon {
  color: #FFFFFF;
  transition: all .3s ease-in-out;
}

.search-border {
  border-bottom: 2px solid #515151;
  width: 263px;
  height: 17px;
  position: absolute;
  top: 31px;
}

.search-loupe-icon {
  position: absolute;
  top: 10px;
  right: -32px;
}

.selected-filters-query {
  font-family: "suisse_intl_monoregular";
  display: flex;
  color: #989898;
  flex-wrap: wrap;
  margin-top: 5px;
}

.selected-filters-query > div:not(:last-child) {
  margin-right: 10px;
}

.selected-filters-query > div {
  margin-bottom: 10px;
}

.query-item {
  display: flex;
  align-items: center;
  border: 2px solid #515151;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  padding: 0px 15px;
}

.query-item:hover {
  border: 2px solid #21db70;
  transition: all .3s ease-in-out;
}

.query-item:hover .query-item-close-icon {
  color: #21db70;
  transition: all .5s ease-in-out;
}

.query-item:hover .query-item-label {
  color: #FFFFFF;
  transition: all .5s ease-in-out;
}

.query-item > div:not(:last-child) {
  margin-right: 22px;
}

.query-item-label {
  font-size: 11px;
  color: #969696;
}

.query-item-close-icon {
  color: #707070;
}

.query-item-close-icon:hover {
  color: #FFFFFF !important;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .search {
    flex-direction: column;
  }

  .selected-filters-query {
    margin-left: 71px;
  }
}

@media screen and (max-width: 666px) {
  .form-checkboxes-wrapper > div:not(:last-child) {
    margin-bottom: 70px;
    margin-right: 0px;
  }

  .form-checkboxes-wrapper {
    max-height: 100%;
    height: 100%;
    width: auto;
    flex-direction: column;
  }

  .search-form-wrapper {
    max-height: 100%;
  }

  .checkbox-label-count {
    color: #4A4A4A !important;
    font-size: 12px !important;
  }
}

@media screen and (max-width: 400px) {
  input[type="text"] {
      width: 120px;
  }

  .search-border {
    width: 238px;
  }

  .search-wrap {
    width: 294px;
  }
}