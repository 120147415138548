.item {
  font-family: "suisse_intl_monoregular";
  font-size: 11px;
  flex-basis: 15%;
  background-color: #1f1f1f;
  border: solid 1px #363636;
  width: 100%;
  color: #afafaf;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;

  height: 0;
  padding-top: 100%;
  position: relative;
}

.hashtags {
  display: flex;
}

.aside {
  position: absolute;
  right: 50px;
  bottom: 60px;
}

.item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.item-content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-image {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
}

.main-text {
  font-size: 14px;
  color: #ececec;
  text-align: center;
  line-height: 16.8px;
}

.item-description-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  
  background-color: #21db70;
}

.item-description {
  font-family: "suisse_intl_monoregular";
  color: white;
  font-size: 14px;
  width: 100%;
  text-align: center;
  line-height: 15px;
}

.picture-wrapper {
  position: absolute;
  top: -100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #ffffff;
}

.padding-15 {
  padding: 15px;
}

.image-over {
  width: 100%;
  height: 100%;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.hover-after-image-wrapper {
  position: absolute;
  top: -100%;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #ffffff;
  display: none;
}

.item-content-active-desctop:hover > .hover-after-image-wrapper {
  display: flex;
}

.item-content-active-mobile[data-active="true"] > .hover-after-image-wrapper {
  display: flex;
}

.item-content-active-mobile[data-active="false"] > .hover-after-image-wrapper {
  display: none;
}

.item-before-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #21db70;
  display: none;
}

.item-content-active-desctop:hover > .item-before-wrapper {
  display: flex;
}

.item-content-active-mobile[data-active="true"] > .item-before-wrapper {
  display: flex;
}

.item-content-active-mobile[data-active="false"] > .item-before-wrapper {
  display: none;
}