.header {
  width: 100%;
  background-color: #21db70;
}

.header-content {
  display: flex;;
  align-items: center;
  height: 180px;
  
  display: flex;
  align-items: center;
  max-width: 1525px;
  margin: 0 auto;
  justify-content: space-between;
  padding: 0px 10px;
}

.header-content > div:not(:last-child) {
  margin-right: 20px;
}

.creature-image {
  margin-bottom: -3px;
}

.header-description {
  margin-right: 50px;
  font-family: "suisse_intl_monoregular";
  font-size: 11px;
  line-height: 22px;
}

.header p {
  color: #f7fcf8;
}

.header p:nth-child(1) {
  text-decoration: underline;
}

.x {
  border: 2px solid black;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.header-title {
  font-family: "DrukTextWide-Medium";
  font-size: 24px;
  line-height: 36px;
  font-weight: 200;
  color: #F25763;
  text-transform: uppercase;
  position: relative;
  left: -30px;
}

.header-title-fix {
  position: absolute;
}

.banner-wrapper {
  display: flex;
  align-items: center;
}

.creature-wrapper {
  display: flex;
  align-items: center;
}

.creature-wrapper > div:not(:last-child) {
  margin-right: 20px;
}

@media screen and (max-width: 1120px) {
  .header-description {
    display: none;
  }
}

@media screen and (max-width: 666px) {
  .header-title {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .banner-wrapper {
    display: none;
  }
}