:local {
    .searchWrapper {
        position: relative;
    }
    .searchWrapper[data-mask="true"] {
        z-index: 1;
    }

    .listContainer {
        position: relative;
    }

    .listContainer[data-mask="true"]::after {
        content: "";
        position: absolute;
        top: 0px;
        width: 100%;
        height: 370px;
        background-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
        pointer-events: none;
    }

    .listWrapper {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        grid-gap: 40px;
        margin: 0 auto;
        max-width: 1525px;
        padding: 0px 10px 40px;
    }

    .listWrapper[data-mask="true"] {
        padding: 40px 10px 40px;
    }

    @media screen and (max-width: 1510px) {
        .listWrapper {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    @media screen and (max-width: 1300px) {
        .listWrapper {
            grid-template-columns: repeat(4, 1fr);

        }
    }

    @media screen and (max-width: 1050px) {
        .listWrapper {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media screen and (max-width: 770px) {
        .listWrapper {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media screen and (max-width: 400px) {
        .listWrapper {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}