:local {

    input[type="checkbox"] {
        display: none;
    }

    .checkbox[data-checked="true"] + .checkboxLabel .label {
        color: #FFFFFF;
    }

    .labelWrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .labelWrapper > div:not(:last-child) {
        margin-right: 15px;
    }

    .labelWrapper:hover .label {
        color: #FFFFFF;
        transition: all .3s ease-in-out;
    }

    .label {
        color: #989898;
    }

    .icon {
        border: 1px solid rgba(128, 128, 128, 0.5);
        width: 20px;
        height: 20px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon::before {
        content: "";
        opacity: 0;
        width: 8px;
        height: 8px;
        text-align: center;
        /* font-weight: 600; */
        color: #21db70;
        background-color: #21db70;
    }
      
    .checkbox:checked + .checkboxLabel .icon::before {
        opacity: 1;
        transition: all .3s ease-in-out;
    }

    .componentContent {
        display: flex;
    }
}