:local {
    formControl: {
        margin-top: 10;
        display: block;
    }

    .formControl > div:not(:last-child) {
        margin-bottom: 10px;
    }
    
    errorMsg: {
        color: red;
        font-weight: bold;
        font-size: 14;
        margin-top: 0px;
    }

    errorColor: {
        color: red;
    }

    title: {
        padding-bottom: 5px;
        padding-top: '5px';
        font-size: 14;
        font-weight: 500;
    }
}