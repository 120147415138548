:local {
  .hashtagsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
  }

  .hashtagsWrapper > div {
    line-height: 15px;
  }

  .hashtagsWrapper > div:not(:last-child) {
      margin-right: 5px;
      display: flex;
      flex-wrap: wrap;
  }
}