.footer {
  font-family: "suisse_intl_monoregular";
  font-size: 11px;
  font-weight: 600;
  background-color: #3a3939;
  margin-top: auto;
  width: 100%;
}

.footer-content {
  max-width: 1525px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0px 10px;
}

.footer p,
.social-media-icons p {
  color: #999999;
}

.be {
  color: #3a3939;
}

.social-media-icons {
  display: flex;
  align-items: center;
}

.be {
  width: 19px;
  height: 19px;
  margin-left: 18px;
  cursor: pointer;
}

.v,
.fb,
.twitter,
.insta {
  width: 19px;
  height: 19px;
  margin-left: 7px;
  cursor: pointer;
}
