
.top-bar-wrapper {
  background-color: #1f1f1f;
}

.top-bar {
  height: 68px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 10px;
  max-width: 1525px;
  margin: 0 auto;
}

.top-bar > div:not(:last-child) {
  margin-right: 15px;
}

.top-bar h1 {
  font-family: "suisse_intl_monoregular";
  color: #20dc6c;
  font-size: 18px;
  font-weight: 400;
}

.logo {
  background-image: url(../../../../../img/logo.png);
}
