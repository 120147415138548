:local {
    .layoutWrapper {
        background-color: #151515;
        min-height: 100vh;
        display: flex;
        flex-direction: column
    }
    
    .content {
        height: 100%;
    }
}