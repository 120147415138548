@font-face {
  font-family: "suisse_intl_monoregular";
  src: url("./fonts/suisse_intl_mono-webfont.woff2") format("woff2"),
    url("./fonts/suisse_intl_mono-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DrukTextWide-Medium";
  src: url("./fonts/DrukTextWide-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/DrukTextWide-Medium.otf") format("opentype");
  /* url("./fonts/DrukTextWide-Medium.woff") format("woff"), */
  /* url("./fonts/DrukTextWide-Medium.ttf") format("truetype"), */
  /* url("./fonts/DrukTextWide-Medium.svg#DrukTextWide-Medium") format("svg"); */
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
